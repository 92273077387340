import type { ReactNode } from "react";
import LR from "../../components/LeftRight.module.css";

import { LeftRight } from "../../components/LeftRight.tsx";
import { WSelect, type ConfiguredWSelectProps } from "../../components/forms/WSelect.tsx";
import { WSelectWrapper } from "../../components/forms/WSelectWrapper.tsx";
import { ResourceIcon } from "../../components/icon/ResourceIcon.tsx";
import { useStandardSuspenseQuery } from "../api/useStandardMutation.ts";
import { getSingleQuery, type VpcWithType } from "./vpc/apiOperations.ts";

export interface VpcNetworkSelectProps extends ConfiguredWSelectProps<VpcWithType, string> {
    location: string;
}

function ShortInfo({ item }: { item: VpcWithType }): ReactNode {
    return item.subnet;
}

function getDefaultVpc(items: readonly VpcWithType[]) {
    return items.find((s) => s.is_default);
}

export function VpcNetworkSelectCustom(props: VpcNetworkSelectProps) {
    return (
        <WSelect
            itemClassName={LR.item}
            valueClassName={LR.value}
            aria-label="Select virtual private network"
            dynamicDefault={getDefaultVpc}
            getKey={(item) => item.uuid}
            getTextValue={(item) => item.name ?? ""}
            searchItems={(query, items) =>
                items.filter((item) => (item.name ?? "").toLowerCase().includes(query.toLowerCase()))
            }
            {...props}
        >
            {(item) => (
                <LeftRight icon={<ResourceIcon item={item} />} title={item.name} right={<ShortInfo item={item} />} />
            )}
        </WSelect>
    );
}

export type VpcNetworkSelectLoaderProps = Omit<VpcNetworkSelectProps, "items">;

function VpcNetworkSelectLoader(props: VpcNetworkSelectLoaderProps) {
    const { location } = props;
    const { data } = useStandardSuspenseQuery(getSingleQuery, { location });
    return <VpcNetworkSelectCustom items={[...data.values()]} {...props} />;
}

export function VpcNetworkSelect(props: VpcNetworkSelectLoaderProps) {
    return (
        <WSelectWrapper fallback={(renderProps) => <VpcNetworkSelectCustom {...props} {...renderProps} />}>
            <VpcNetworkSelectLoader {...props} />
        </WSelectWrapper>
    );
}
