import FF from "../../components/forms/FormField.module.css";
import TF from "../../components/forms/TextField.module.css";

import { useControlledState } from "@react-stately/utils";
import { Navigate } from "@tanstack/react-router";
import type { ObjectStorageCreateBody } from "@warrenio/api-spec/spec.oats.gen";
import { findById } from "@warrenio/utils/collections/findById";
import { unreachableSwitchCase } from "@warrenio/utils/unreachable";
import { Suspense, type ReactNode } from "react";
import { Input } from "react-aria-components";
import { useForm, useFormContext, type SubmitHandler } from "react-hook-form";
import { HeroBlock } from "../../components/HeroBlock.tsx";
import { Separator } from "../../components/Separator.tsx";
import { CreateFormAction } from "../../components/forms/CreateFormAction.tsx";
import { IconRadio, type IconRadioContentProps } from "../../components/forms/IconRadio.tsx";
import { MonthlyCostElement, MonthlyCostField } from "../../components/forms/MonthlyCostField.tsx";
import { WHookForm } from "../../components/forms/WHookForm.tsx";
import { WRadioGroup } from "../../components/forms/WRadioGroup.tsx";
import { WTextField, type WTextFieldProps } from "../../components/forms/WTextField.tsx";
import { useAriaField } from "../../components/forms/ariaFieldRegister.ts";
import { requiredMessage } from "../../components/forms/requiredMessage.ts";
import { LoadingSuspense } from "../../components/loading/Loading.tsx";
import { cn } from "../../utils/classNames.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { BillingAccountField } from "../billing/forms/BillingAccountField.tsx";
import { PricingModal } from "../pricing/PricingModal.tsx";
import { pricesAtom } from "../pricing/query.ts";
import { getBucketPricePerGB } from "../pricing/resourcePricing.ts";
import { objectStorageLink } from "./links.ts";
import { createObjectStorageMutation } from "./objectStorage/apiOperations.ts";

interface ItemType extends IconRadioContentProps {
    description: ReactNode;
}

export type StorageFormType = "bucket";

const types = [
    {
        id: "bucket",
        title: "Object Storage",
        icon: "jp-archive-icon",
        description:
            "Object Storage service allows you to scale your storage resources up and down without any upfront payments.",
    },
] as const satisfies (ItemType & { id: StorageFormType })[];

export interface CreateProps {
    typeId?: StorageFormType;
    onChangeType?: (type: StorageFormType) => void;
}

function StorageForm({ typeId: controlledTypeId, onChangeType }: CreateProps) {
    const [typeId, setType] = useControlledState(controlledTypeId, types[0].id, onChangeType);

    const type = findById(types, typeId ?? "bucket"); //TODO: hetkel aint 1 valik tegelt

    return (
        <>
            <WRadioGroup label="Type" isRequired value={typeId} onChange={setType} description={type.description}>
                {types.map((item) => (
                    <IconRadio key={item.id} {...item} />
                ))}
            </WRadioGroup>
            <Separator />

            <LoadingSuspense>
                {typeId === "bucket" ? <ObjectStorageCreateForm /> : unreachableSwitchCase(typeId)}
            </LoadingSuspense>
        </>
    );
}

interface ObjectStorageCreateInputs extends ObjectStorageCreateBody {}

export function ObjectStorageCreateForm({ inputs }: { inputs?: Partial<ObjectStorageCreateInputs> }) {
    const objectStorageMutation = useStandardMutation(createObjectStorageMutation);
    const form = useForm<ObjectStorageCreateInputs>({
        disabled: objectStorageMutation.isPending,
        defaultValues: {
            ...inputs,
        },
    });

    const onSubmit: SubmitHandler<ObjectStorageCreateInputs> = async ({ ...body }) => {
        await objectStorageMutation.mutateAsync({ body });
    };

    if (objectStorageMutation.isSuccess) {
        return <Navigate {...objectStorageLink(objectStorageMutation.data)} />;
    }

    return (
        <WHookForm form={form} onSubmit={onSubmit}>
            <StorageNameField isRequired />

            <Separator />

            <Suspense>
                <ObjectStorageMonthlyCostField />
            </Suspense>

            <BillingAccountField resourceType="bucket" isRequired />
            <Separator />

            <CreateFormAction resourceType="bucket" />
        </WHookForm>
    );
}

export function ObjectStorageMonthlyCostField() {
    const prices = useSuspenseQueryAtom(pricesAtom);
    const storageCost = getBucketPricePerGB(prices);

    return (
        <MonthlyCostField
            description={
                <>
                    Actual cost of your storage is calculated hourly based on your actual usage, no upfront payments.
                    For every started GB the price is rounded up to next whole GB.{" "}
                    <PricingModal>Please see how the cost of your resource is calculated.</PricingModal>
                </>
            }
        >
            <MonthlyCostElement price={storageCost} />
        </MonthlyCostField>
    );
}

// function

function StorageNameField(textFieldProps: WTextFieldProps) {
    const form = useFormContext<ObjectStorageCreateInputs>();
    const { ref, props } = useAriaField("name", form, {
        required: requiredMessage,
        validate: (value) => {
            if (!/^[a-z0-9][a-z0-9\-]*[a-z0-9]$/.test(value) || value.length < 3 || value.length > 63) {
                return "Please enter a valid name";
            }
            return true;
        },
    });
    return (
        //TODO: fix description
        <WTextField
            {...props}
            label="Storage name"
            description="Storage name can only contain lowercase alphanumeric characters and dashes, name must start with a lowercase letter or number, allowed length is 3–63."
            {...textFieldProps}
        >
            <Input ref={ref} className={cn(FF.FormFieldInput, TF.Input)} />
        </WTextField>
    );
}

export function StorageCreate(props: CreateProps) {
    return (
        <>
            <CreateHero />
            <StorageForm {...props} />
        </>
    );
}

function CreateHero() {
    return (
        <HeroBlock title="Create New Storage" icon="jp-stack-icon">
            <p className="text-muted">
                Object Storage service allows you to scale your storage resources up and down without any upfront
                payments. Create a new storage bucket and start storing your data in a scalable way - Pay-as-you-grow.
            </p>
        </HeroBlock>
    );
}
