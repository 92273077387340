import type { VmImage } from "@warrenio/api-spec/spec.oats.gen";
import { reverse, sortBy } from "remeda";
import { Tile, TileSelect } from "../../../components/forms/TileSelect.tsx";
import { VmImageIcon } from "../VmImageIcon.tsx";
import type { SelectedOs } from "./os.ts";

export interface OsVersionRadioProps {
    items: VmImage[];

    value?: SelectedOs;
    onChange?: (os: SelectedOs) => void;
}

export function OsVersionSelectCustom({ value, onChange, items }: OsVersionRadioProps) {
    const sortedItems = sortBy(items, (l) => l.ui_position);

    const tiles = sortedItems.map((item) => {
        const { os_name, display_name, versions } = item;

        const sortedVersions = reverse(versions);

        return (
            <Tile<SelectedOs>
                key={os_name}
                icon={(isSelected) => <VmImageIcon image={item} isSelected={isSelected} className="size-2rem" />}
                title={display_name}
                optionHeader="Version"
                items={sortedVersions.map(({ display_name, os_version }) => ({
                    key: os_version,
                    value: { os_name, os_version },
                    label: display_name,
                }))}
            />
        );
    });

    return (
        <TileSelect<SelectedOs>
            value={value}
            equal={(a, b) => a.os_name === b.os_name && a.os_version === b.os_version}
            onChange={onChange}
        >
            {tiles}
        </TileSelect>
    );
}
