import LR from "../../components/LeftRight.module.css";

import { LeftRight } from "../../components/LeftRight.tsx";
import { type ConfiguredWSelectProps, WSelect } from "../../components/forms/WSelect.tsx";
import { WSelectWrapper } from "../../components/forms/WSelectWrapper.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { hostPoolQueryAtom, type HostPoolWithType } from "../pools/query.ts";

export interface PoolSelectProps extends ConfiguredWSelectProps<HostPoolWithType, string> {
    location: string;
}

function PoolIcon() {
    return <MaskIcon className="size-1.125rem jp-cluster-icon text-primary" />;
}

export function PoolSelectCustom({ items, ...props }: PoolSelectProps) {
    const { onChange } = props;

    return (
        <WSelect
            itemClassName={LR.item}
            valueClassName={LR.value}
            aria-label="Select host pool"
            items={items}
            getKey={(item) => item.uuid}
            getTextValue={(item) => item.name}
            searchItems={(query, items) =>
                items.filter((item) => item.name.toLowerCase().includes(query.toLowerCase()))
            }
            onChange={onChange}
            {...props}
        >
            {(item) => <LeftRight icon={<PoolIcon />} title={item.name} />}
        </WSelect>
    );
}

export type PoolSelectLoaderProps = Omit<PoolSelectProps, "items">;

function PoolSelectLoader(props: PoolSelectLoaderProps) {
    const poolsMap = useSuspenseQueryAtom(hostPoolQueryAtom(props.location));

    return <PoolSelectCustom items={[...poolsMap.values()]} {...props} />;
}

export function PoolSelect(props: PoolSelectLoaderProps) {
    return (
        <WSelectWrapper fallback={(renderProps) => <PoolSelectCustom {...props} {...renderProps} />}>
            <PoolSelectLoader {...props} />
        </WSelectWrapper>
    );
}
