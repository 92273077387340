import FF from "./FormField.module.css";

import type { ComponentProps, ReactNode } from "react";
import type { Price } from "../../modules/pricing/resourcePricing.ts";
import { cn } from "../../utils/classNames.ts";
import { CurrencyHourly, CurrencyMonthly } from "../l10n/Currency.tsx";
import { FormField } from "./FormField.tsx";

interface MonthlyCostElementProps extends ComponentProps<"div"> {
    price: Price;
    emphasize?: boolean;
    inline?: boolean;
    compact?: boolean;
    title?: string;
}

const denominator = (period: "month" | "hour", price: Price) => `/${price.unit ? ` ${price.unit} per` : ""} ${period}`;

const monthlyElement = (price: Price, clsName?: string) => (
    <>
        <span className={clsName} data-testid="priceValue">
            <CurrencyMonthly value={price.hourly} />
        </span>{" "}
        <span className="text-nowrap">{denominator("month", price)}</span>
    </>
);

export function MonthlyCostElement({
    price,
    emphasize = true,
    inline,
    compact,
    title,
    ...divProps
}: MonthlyCostElementProps) {
    const fontSize = emphasize ? "font-size-title" : "";
    const hourlyElement = (
        <>
            <span data-testid="priceValue">
                <CurrencyHourly value={price.hourly} compact={compact} />
            </span>{" "}
            <span className="text-nowrap">{denominator("hour", price)}</span>
        </>
    );
    return (
        <div {...divProps}>
            {title && (
                <div className={cn(FF.FormFieldMessage, "color-muted", compact && "font-size-small")}>{title}</div>
            )}
            {price.hourly === 0 ? (
                <div className={fontSize}>{inline ? <b>Free</b> : "Free"}</div>
            ) : inline ? (
                <>
                    <b>{monthlyElement(price, fontSize)}</b> ({hourlyElement})
                </>
            ) : (
                <>
                    <div>
                        {monthlyElement(price, fontSize)}
                        <span className="font-size-title" />
                    </div>
                    <div>{hourlyElement}</div>
                </>
            )}
        </div>
    );
}

interface SimpleMonthlyCostElementProps extends ComponentProps<"div"> {
    price: Price;
    show_zero_as: "FREE" | "NUMBER";
}

export function SimpleMonthlyCostElement({ price, show_zero_as = "FREE", ...divProps }: SimpleMonthlyCostElementProps) {
    const showFreeText = show_zero_as === "FREE" && price.hourly === 0;
    return <div {...divProps}>{showFreeText ? <div>Free</div> : <>{monthlyElement(price)}</>}</div>;
}

export function MonthlyCostField({
    description,
    children,
}: {
    description: ReactNode;
    children: ReactNode | ReactNode[];
}) {
    return (
        <FormField label="Est. monthly cost" description={description}>
            <div className={FF.FormFieldHolder} data-testid="monthlyCost">
                {children}
            </div>
        </FormField>
    );
}
