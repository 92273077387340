import { useSuspenseQueryAtom } from "../../../utils/query/useSuspenseQueryAtom.ts";
import { imagesByOsNameAtom } from "../vmImagesQuery.ts";
import type { SelectedOs } from "./os.ts";

export function useOs({ os_name, os_version }: SelectedOs) {
    const allImages = useSuspenseQueryAtom(imagesByOsNameAtom);

    const os = allImages.get(os_name);
    const version = os?.versions.find((item) => item.os_version === os_version);
    return { os, version, title: `${os?.display_name ?? os_name} ${version?.display_name ?? os_version}` };
}
