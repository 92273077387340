import type { PriceFieldsBase } from "@warrenio/api-spec/spec.oats.gen";
import { type PropsWithChildren, type ReactNode, useRef, useState } from "react";
import { type AriaButtonOptions, useButton } from "react-aria";
import { Label } from "react-aria-components";
import { SiteName } from "../../components/SiteName.tsx";
import { CurrencyHourly, CurrencyMonthly } from "../../components/l10n/Currency.tsx";
import { LoadingSuspense } from "../../components/loading/Loading.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { WTable, WTableBody } from "../../components/table/WTable.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { LocationSelectCustom } from "../location/LocationSelect.tsx";
import { PoolSelectCustom } from "../location/PoolSelect.tsx";
import { getPreferredLocation, type LocationWithEnabled } from "../location/location.ts";
import type { LocationSlug } from "../location/query.ts";
import { usePricingLocations } from "../location/query.ts";
import { getDefaultHostPool } from "../pools/logic.ts";
import { hostPoolQueryAtom, type HostPoolUUID, type HostPoolWithType } from "../pools/query.ts";
import { pricesAtom } from "./query.ts";
import { getLocationPrices } from "./resourcePricing.ts";

function ShowPricingModalButton({ children, ...props }: PropsWithChildren<AriaButtonOptions<"span">>) {
    const ref = useRef(null);
    const { buttonProps } = useButton(
        {
            ...props,
            elementType: "span",
        },
        ref,
    );

    return (
        <span className="color-primary cursor-pointer" ref={ref} {...buttonProps}>
            {children}
        </span>
    );
}

export function PricingTable({ slug, poolId }: { slug: LocationSlug; poolId?: HostPoolUUID }) {
    const pricing = useSuspenseQueryAtom(pricesAtom);

    const locationPrices = getLocationPrices(pricing, slug, poolId);
    const priceRows: PriceFieldsBase[] = Object.entries(locationPrices).flatMap(([_resourceType, value]) => value);

    return (
        <WTable>
            <thead>
                <tr>
                    <th>Item</th>
                    <th className="text-right">Price Per Hour</th>
                    <th className="text-right">Price Per Month</th>
                </tr>
            </thead>
            <WTableBody>
                {priceRows.map((item, index) => (
                    <tr key={index}>
                        <td className="color-primary">{item.description}</td>
                        <td className="text-right">
                            <CurrencyHourly value={item.price_per_unit} />
                        </td>
                        <td className="text-right">
                            <CurrencyMonthly value={item.price_per_unit} accurate={true} />
                        </td>
                    </tr>
                ))}
            </WTableBody>
        </WTable>
    );
}

export interface PricingModalProps {
    children: ReactNode;
}

export function PricingModal({ children, ...props }: PricingModalProps & PricingModalContentProps) {
    return (
        <WModal button={<ShowPricingModalButton>{children}</ShowPricingModalButton>}>
            <PricingModalContent {...props} />
        </WModal>
    );
}

interface PricingModalContentProps extends PricingModalDataProps {}

export function PricingModalContent(props: PricingModalContentProps) {
    return (
        <WModalContent title="Pricing List" modalAction={undefined}>
            <p className="pb-4">
                <SiteName /> has transparent and predictable pricing. The actual cost of your resource is calculated
                based on the below prices and your actual hourly usage. All prices are per one unit of resource (1 CPU
                or 1 GiB). Prices do not contain VAT.
            </p>
            <p className="font-size-subtitle pb-3">Prices may vary for the options below</p>

            <LoadingSuspense>
                <PricingModalData {...props} />
            </LoadingSuspense>
        </WModalContent>
    );
}

export interface PricingModalDataProps {
    defaultLocation?: LocationSlug;
    defaultHostPool?: HostPoolUUID | null;
}

function PricingModalData({ defaultLocation, defaultHostPool }: PricingModalDataProps) {
    //#region Hooks

    const locations = usePricingLocations();

    const [selectedLocation, setSelectedLocation] = useState<LocationWithEnabled>();
    const [selectedPool, setSelectedPool] = useState<HostPoolWithType>();

    const location = selectedLocation ?? getPreferredLocation(locations, defaultLocation);
    const poolsMap = useSuspenseQueryAtom(hostPoolQueryAtom(location.slug));

    //#endregion Hooks

    const locationPools = [...poolsMap.values()];
    const pool = selectedPool ?? getDefaultHostPool(locationPools, defaultHostPool);

    function onChangeLocation(location: LocationWithEnabled) {
        setSelectedPool(undefined);
        setSelectedLocation(location);
    }

    // TODO: should locations always be shown in some cases?
    const hasLocations = locations.length > 1;
    // TODO: should host pool be shown when there is only one?
    const hasPools = locationPools.length > 1;
    const hasSelects = hasLocations || hasPools;

    return (
        <>
            {hasSelects && (
                <div className="flex gap-1rem pb-4 justify-between">
                    {hasLocations && (
                        <div className="flex-1">
                            <Label className="block pb-1">Location:</Label>
                            <LocationSelectCustom items={locations} value={location} onChange={onChangeLocation} />
                        </div>
                    )}

                    {/* Always leave space for the pool selector because it can (dis)appear when switching between locations */}
                    <div className="flex-1">
                        {hasPools && (
                            <>
                                <Label className="block pb-1">Server class:</Label>
                                <PoolSelectCustom
                                    items={locationPools}
                                    location={location.slug}
                                    value={pool}
                                    onChange={setSelectedPool}
                                />
                            </>
                        )}
                    </div>
                </div>
            )}

            <PricingTable slug={location.slug} poolId={pool?.uuid} />
        </>
    );
}
